<template>
    <div class='adchannelManager'>
        <div class="planlist">
            <!-- <el-row style="margin-bottom:1rem">
                <el-col :span="24" style="text-align:left">
                    <el-input style="width:200px;" v-model="paginationInfo.condition.mobile" maxlength="11"
                        placeholder="请输入手机号" clearable></el-input>
                    <el-date-picker v-model="paginationInfo.condition.begin_date" align="right" type="date"
                        placeholder="选择开始时间" :picker-options="{
                            disabledDate(time) {
                                return time.getTime() > Date.now();
                            },
                        }">
                    </el-date-picker>
                    <el-date-picker v-model="paginationInfo.condition.end_date" align="right" type="date"
                        placeholder="选择结束时间" :picker-options="{
                            disabledDate(time) {
                                return time.getTime() > Date.now();
                            },
                        }">
                    </el-date-picker>
                    <el-select v-model="paginationInfo.condition.app_code" placeholder="请选择订单状态" @change="queryList"
                        filterable clearable>
                        <el-option label="language会员" value="languagemember"></el-option>
                        <el-option label="冰蓝科技" value="blkjwechatpay"></el-option>
                        <el-option label="陀螺领购" value="tllgwechatpay"></el-option>
                    </el-select>
                    <el-button @click="queryList">查询</el-button>
                </el-col>
            </el-row> -->
            <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="name" label="渠道名称" align='center' width="150">
                </el-table-column>
                <el-table-column prop="code" label="渠道编码" align='center'>
                </el-table-column>
                <el-table-column prop="pass" label="回传数量" align='center'>
                </el-table-column>
                <el-table-column prop="skip" label="扣单数量" align='center'>
                </el-table-column>
                <el-table-column prop="skip_percent" label="扣单比例" align='center' width="300">
                    <template slot-scope="scope">
                        <div>
                            {{ Math.floor(scope.row.skip_percent * 100) + '%' }}
                            <el-button type="primary" plain @click="handleItemSkipPercent('show', scope.row)"
                                size="mini">修改扣单比例</el-button>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="total" label="总数" align='center'>
                </el-table-column>
                <el-table-column  label="操作" align='center'>
                    <template slot-scope="scope">
                        <div>
                            <el-button type="warning" plain @click="handleNextFillInAccount(scope.row)"
                                size="mini">下一单指定账号</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <el-dialog title="修改扣单比例" :visible.sync="handleSkipPercentType" width="30%" :before-close="handleClose">
                <div>
                    <el-select v-model="formData.skip_percent" placeholder="请选择">
                        <el-option v-for="item in skipPercentOptions" :key="item" :label="`${Math.floor(item * 100)}%`"
                            :value="item">
                        </el-option>
                    </el-select>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="handleSkipPercentType = false">取 消</el-button>
                    <el-button type="primary" @click="handleItemSkipPercent('handle')">确 定</el-button>
                </span>
            </el-dialog>
            <el-dialog title="下一单指定账号" :visible.sync="nextFillInVisable" width="30%">
                <div>
                    <el-select v-model="nextFillInForm.app_code" placeholder="请选择">
                        <el-option v-for="item in accountChannelList" :key="item.dictdata_value"
                            :label="item.dictdata_name" :value="item.dictdata_value" />
                    </el-select>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="nextFillInVisable = false">取 消</el-button>
                    <el-button type="primary" @click="confirmNextFillInAccount">确 定</el-button>
                </span>
            </el-dialog>
            <!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="paginationInfo.pageNo" :page-sizes="[10, 20, 50, 100]"
                :page-size="paginationInfo.pageSize" :total="paginationInfo.total"
                layout="total, sizes, prev, pager, next, jumper">
            </el-pagination> -->
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            handleSkipPercentType: false,
            nextFillInVisable: false,
            accountChannelList: [],
            skipPercentOptions: [0.00, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.8, 0.9],
            paginationInfo: {
                pageSize: 10,
                total: 0,
                pageNo: 1,
                condition: {
                    // begin_date: "",
                    // end_date: "",
                    // app_code: "languagemember",
                    // mobile: '',
                    // status: '',
                }
            },
            formData: {
                skip_percent: ""
            },
            nextFillInForm: {
                app_code: '',
                channel_code: ''
            },
            tableData: [],
        }
    },
    computed: {},
    watch: {},
    created() {
        // this.paginationInfo.condition.begin_date = this.$moment(new Date()).format('YYYY-MM-DD');
        // this.paginationInfo.condition.end_date = this.$moment(new Date()).format('YYYY-MM-DD');
        this.queryList();
    },
    mounted() {
        this.getAccountChannel()
    },
    methods: {
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(() => {
                    done();
                })
                .catch(() => { });
        },
        handleItemSkipPercent(type, item) {
            switch (type) {
                case "show":
                    this.handleSkipPercentType = true;
                    this.formData.skip_percent = item.skip_percent;
                    this.formData._id = item._id;
                    break;
                case "handle":
                    this.$service.OnlineOrderService
                        .getAdchannelManagerRulesReset(this.formData)
                        .then(res => {
                            if (res.data.code === 0) {
                                this.handleSkipPercentType = false;
                                this.getAdchannelManagerRules();
                                this.$message({
                                    message: '修改成功！',
                                    type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.data.message,
                                    type: 'error'
                                });
                            }
                        }).catch((err) => {
                            window.console.log(err)
                            this.$message({
                                message: '网络错误，请重新修改！',
                                type: 'error'
                            });
                        })
                    break;

            }
        },
        queryList() {
            this.paginationInfo.pageNo = 1;
            this.getAdchannelManagerRules();
        },
        handleSizeChange(val) {
            window.console.log(`每页 ${val} 条`);
            this.paginationInfo.pageSize = val;
            this.paginationInfo.pageNo = 1;
            this.getAdchannelManagerRules();
        },
        handleCurrentChange(val) {
            window.console.log(`当前页: ${val}`);
            this.paginationInfo.pageNo = val;
            this.getAdchannelManagerRules();
        },
        handleNextFillInAccount(row) {
            this.nextFillInForm.channel_code = row.code
            this.nextFillInVisable = true
        },
        confirmNextFillInAccount() {
            if(!this.nextFillInForm.channel_code) {
                return
            }
            if(!this.nextFillInForm.app_code) {
                this.$message({
                    message: '请选择账号',
                    type: 'success'
                });
                return
            }
            this.$service.OnlineOrderService
                .setFillInStandBy({
                    channel_code: this.nextFillInForm.channel_code,
                    app_code: this.nextFillInForm.app_code
                })
                .then(res => {
                    if (res.data.code === 0) {
                        this.$message({
                            message: '已提交',
                            type: 'success'
                        });
                        this.nextFillInVisable = false
                    }
                })
        },
        getAccountChannel() {
            this.$service.CommonService
                .getDict({ dict: 'AlipayAccountOptions' })
                .then(res => {
                    if (res.data.code === 0) {
                        this.accountChannelList = res.data.data.dict_data;
                    }
                })
        },
        getAdchannelManagerRules() {
            this.$service.OnlineOrderService
                // .getAdchannelManagerRules(this.paginationInfo)
                .getAdchannelManagerRules()
                .then(res => {
                    if (res.data.code === 0) {
                        window.console.log(res.data.data)
                        this.tableData = JSON.parse(JSON.stringify(res.data.data));
                        // this.paginationInfo.total = this.tableData.length || 1000;
                        this.tableData.forEach(item => {
                            item.handleSkipPercentType = false;
                        });
                    }
                })
        },
    },
    beforeCreate() { }, // 生命周期 - 创建之前
    beforeMount() { }, // 生命周期 - 挂载之前
    beforeUpdate() { }, // 生命周期 - 更新之前
    updated() { }, // 生命周期 - 更新之后
    beforeDestroy() { }, // 生命周期 - 销毁之前
    destroyed() { }, // 生命周期 - 销毁完成
    activated() { } // 如果页面有keep-alive缓存功能,这个函数会触发
}
</script>
